/*.Users-root {*/
/*	max-width: 600px;*/
/*	padding: 10px;*/
/*}*/

.Report-date-from {
	padding-left: 0;
	padding-right: 5px;
}

.Report-date-to {
	padding-left: 5px;
	padding-right: 0;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

#digatex-logo {
	height: 48px;
}

#components-layout-demo-fixed .logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
	background: #fff;
}

.home-button-header {
	font-size: 32px;
	margin-left: 10px;
	color: white
}
