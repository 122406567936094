#menu {
	position: fixed;
	top: 15px;
	right: 40px;
}
#search {
	position: fixed;
	top: 15px;
	left: 40px;
}

#logo {
	height: 48px;
}

#menu button {
	font-size: 1.5em;
}

.g-recaptcha {
	display: inline-block;
}

#g-recaptcha.empty {
	border: 1px solid #a94442;
	border-radius: .25em;
}

.icon-home {
	color: black;
	font-size: 25px;
	vertical-align: -4px;
}

.icon-home-white {
	color: white;
	font-size: 25px;
	vertical-align: -7px;
}

header.ant-layout-header {
	background: linear-gradient(90deg, rgba(0,32,92,1) 0%, rgba(0,75,135,1) 100%);
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	z-index: 999;
}

#home-button > svg {
	position: relative;
	top: calc(50% - 16px);
}
.custom-filter-dropdown {
	padding: 8px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.beautiful-link {
	color: #007fc5;
	text-decoration: underline;
}


