body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Global-admin-root {
  max-width: 1200px;
  padding: 10px;
}

.Global-admin-table {
  max-width: 70%;
}

#menu {
	position: fixed;
	top: 15px;
	right: 40px;
}
#search {
	position: fixed;
	top: 15px;
	left: 40px;
}

#logo {
	height: 48px;
}

#menu button {
	font-size: 1.5em;
}

.g-recaptcha {
	display: inline-block;
}

#g-recaptcha.empty {
	border: 1px solid #a94442;
	border-radius: .25em;
}

.icon-home {
	color: black;
	font-size: 25px;
	vertical-align: -4px;
}

.icon-home-white {
	color: white;
	font-size: 25px;
	vertical-align: -7px;
}

header.ant-layout-header {
	background: linear-gradient(90deg, rgba(0,32,92,1) 0%, rgba(0,75,135,1) 100%);
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	z-index: 999;
}

#home-button > svg {
	position: relative;
	top: calc(50% - 16px);
}
.custom-filter-dropdown {
	padding: 8px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.beautiful-link {
	color: #007fc5;
	text-decoration: underline;
}



.catalog-root {
    max-width: 1400px;
    padding: 10px;
}

.catalog-row-info{
    margin-left:10px;
    margin-right: 10px;
}

.catalog-link{
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: blue;
    float: left;
}

.catalog-dwg-number {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: blue;
}

.catalog-block {
    border: 1px solid #959595;
    border-radius: .5em;
    padding: .1em .3em;
    margin:5px;
}

.catalog-header {
    color: blue;
}

.App {
	text-align: center;
}

.tooltip-inner {
	max-width: 370px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 4px;
}

@media only screen and (max-width: 1200px) {
	input.test-search {
		width: 450px;
	}

	div.search-row {
		width: 450px
	}
}

@media only screen and (min-width: 1520px) {
	input.test-search {
		width: 400px;
	}
	div.search-row {
		width: 560px
	}
}

@media only screen and (min-width: 1710px) {
	input.test-search {
		width: 450px;
	}
	div.search-row {
		width: 700px
	}
}

table.Items tbody tr td {
	line-height: 2.2em;
	border-right-width: 1px;
	border-color: white;
	cursor: default;
}

.beautiful-link {
	color: "#A2C7E2";
	cursor: pointer;
}

table.Items thead tr th {
	line-height: 2.2em;
	text-align: center;
	background-color: #cacacc;
	border-right-width: 1px;
	border-color: white;
	vertical-align: middle;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
	font-size: inherit;
	left: 10px;
	z-index: 2;
	color: black;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
	font-size: inherit;
	right: 10px;
	z-index: 2;
	color: black;
}

a {
	cursor: pointer;
}

.Item-material {
	cursor: pointer;
	word-break: break-word;
	white-space: normal;
}

.Item-part-number {
	text-align: center;
	word-break: break-all;
}

.stock-level button{
	cursor: pointer;
	color: white;
	text-decoration: none !important;
	border: 1px solid #0288D1;
	border-radius: .25em;
	padding: .1em .2em;
	word-break: keep-all;
	min-width: 50px;
	background-color: #0288D1;
}

.Attr {
	border: 1px solid #959595;
	border-radius: .25em;
	padding: .1em .3em;
	margin: .1em .3em .1em 0;
	line-height: 1.5em;
	cursor: default;
	display: inline-block;
}

.Attr-matched {
	cursor: pointer;
}

.Attr-exact {
	background-color: #ccffcc;
}

.Attr-positive {
	background-color: #ffffaa;
}

.Attr-negative {
	background-color: #ffaeae;
}

.App-logo{
	background-color: #00205C;
	border-radius: 0.25em;
	background-size: contain;
	background-repeat: no-repeat;
	background-origin: content-box;
	height: 40px;
	padding-left: 30px;
	margin-top: 8px;
}
.App-query {
	background-color: #00205C;
	background-size: contain;
	background-repeat: no-repeat;
	background-origin: content-box;
	border-top: 1px solid #00205C;
	border-bottom: 1px solid #00205C;
	border-radius: .75em;
	width: 70%;
	height: 55px;
	padding: 1px 12px;
	margin-bottom: 10px;
}

.App-query input {
	margin-top: 15px;
}
.App-query button {
	margin-top: 15px;
}

.App-query img {
	margin-top: 21px;
}

.App-query span {
	font-family: "Courier New";
	font-size: 1.5em;
	color: white;
}

.App-info {
	font-color: #333f4f;
	margin-top: 10px;
	margin-bottom: 10px;
}

.App-info span {
	padding: 20px;
}

.App-root {
	max-width: 1200px;
	padding: 10px;
}

.tooltip.top .tooltip-inner {
	background-color: #333f4f;
}

#requestText {
	width: 100%;
	height: 330px;
	margin-top: 10px;
}

.Contact-form textarea.form-control {
	height: 180px;
}

.form-group.required .control-label:after {
	content: " *";
	color: red;
}
.center{
	margin-left: 14%;
}
.lbl {
	margin-left: 18px;
	margin-right: 10px;
}
.MuiMenu-paper {
	max-height: calc(100% - 96px);
	margin-top: 65px;
	-webkit-overflow-scrolling: touch;
}
.col-md-1 {
	min-height: 1px;
	text-align: center;
	padding-right: 0;
	padding-left: 0;
}
.link {
	margin-left: 40%;
}
.hub-name {
	font-size: 15px;
	text-align: center;
	position: relative;
}


.heading {
	position: static;
	margin: 0;
	padding-top: 6px;
	padding-bottom: 6px;
	color: black;
	font-size: 12px;
	max-height: 38px;
}

.hub-name {
	font-size: 15px;
	text-align: center;
	position: relative;
}

.supplier-copy {
	display: inline-block;
	background-color: #e8e6ff;
	border: 1px solid #1e00ff;
	color: #1e00ff;
	padding: .1em .3em;
	margin: .1em .3em .1em 0;
	line-height: 1.5em;
}

.ant-input-search-button {
	height: 32px;
	margin-top: 10px;
	margin-left: 10px;
}

.site-tree-search-value {
	color: #f50;
}
[data-theme="dark"] .site-tree-search-value {
	color: #d84a1b;
}


.library{
    position: fixed;
    top: 70px;
    margin-left: 10px;
    width: 10%;
    border: 1px solid #959595;
    border-radius: .5em;
    padding: .1em .3em;
    overflow-y: auto;
    max-height: 70%;
}

.Admin-root {
	max-width: 1200px;
	padding: 10px;
}

.Admin-root input[type="file"] {
	margin: 10px 0;
}

.Preview-panel ul {
	float: left;
	margin: 15px 0;
}

.Preview-panel button {
	float: left;
	margin: 15px;
}

.Versions-panel button {
	margin-left: 10px;
}
.Users-root {
    max-width: 1200px;
    padding: 10px;
}

.App-logo{
    background-image: url(/images/digatex.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    height: 40px;
    margin-top: 8px;
}
.User-query {
    /*background-color: #00205C;*/
    border: 1px solid #00205C;
    width: 70%;
    height: 55px;
    margin-bottom: 10px;
}

.User-query input {
    margin-top: 15px;
}
.User-query button {
    margin-top: 15px;
    margin-left: 5px;
}
.commands{
    float: right;
    margin: 10px 0
}
.MuiMenu-list {
    outline: 0;
    max-height: 500px;
}

/*.Users-root {*/
/*	max-width: 600px;*/
/*	padding: 10px;*/
/*}*/

.Report-date-from {
	padding-left: 0;
	padding-right: 5px;
}

.Report-date-to {
	padding-left: 5px;
	padding-right: 0;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

#digatex-logo {
	height: 48px;
}

#components-layout-demo-fixed .logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
	background: #fff;
}

.home-button-header {
	font-size: 32px;
	margin-left: 10px;
	color: white
}

.Account-root {
    max-width: 1200px;
    padding: 10px;
}
.info-row {
    width: 70%;
    font-size: 18px;
}
.password-box{
    border: solid 1px black;
    padding:10px
}
li{
    margin:10px;
    padding: 0;
}

.Account-root input{
    margin: 10px;
    width: 40%
}
.Account-root p{
    margin: 10px
}

